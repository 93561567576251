<template>
  <div class="articles">
    <swiper :options="swiperOption" class="swiperBlock flexItem110">
      <swiper-item
        class="flexColStartRowCenter"
        v-for="(item, index) in banner"
        :key="index"
      >
        <img :src="item.img" @click="bannerClick(item, index)" />
      </swiper-item>
    </swiper>
    <div class="articles-body">
      <div class="articles-search">
        <div class="articles-search__input">
          <input placeholder="请输入关键字" v-model="queryData.title" />
          <div class="articles-search__icon" @click="searchHandle"></div>
        </div>
        <div class="category-list">
          <div
            class="category-item"
            :class="{
              'category-item--checked': queryData.category === item.name,
            }"
            v-for="item of category"
            @click="checkCategory(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="articles-content">
        <div class="articles-empty" v-if="!list.length">
          {{ listLoading ? "内容加载中" : "暂无相关文章或报道" }}
        </div>
        <div class="articles-list" v-else>
          <div
            class="article-item"
            v-for="item of list"
            @click="toDetail(item)"
          >
            <div
              class="article-cover"
              :style="{ backgroundImage: 'url(' + item.cover + ')' }"
            ></div>
            <div class="article-title">{{ item.title }}</div>
            <div class="article-subtitle">{{ item.subtitle }}</div>
          </div>
        </div>
        <Paging
          v-if="totalCount > 0"
          :total="totalCount"
          :current="queryData.page + 1"
          :size="queryData.size"
          :page-count="5"
          @currentChange="handleCurrentChange"
        ></Paging>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { swiper, swiperItem } from "../../components/swiper/index";
import Paging from "../../components/Paging/Paging";
import { pageScroll } from "../../libs/tools";
import { getDevice } from "../../libs/base";

export default {
  name: "knowledge",
  components: {
    swiper: swiper,
    "swiper-item": swiperItem,
    Paging,
  },
  data() {
    return {
      swiperOption: {
        pageBtn: true,
        height: "auto",
        width: "100%",
        device: this.$store.state.device,
        callback: () => {},
        pagination: {
          show: true,
          canClick: true,
        },
        current: 0,
        itemsNum: 2,
        loop: true,
        loopTime: 5000,
        auto: true,
      },
      banner: [
        {
          img: require("../../assets/image/article/news_banner_0.jpg"),
          url: "#/article?id=649806b7e4b0b15677aeebdf",
          type: "blank",
        },
        {
          img: require("../../assets/image/article/news_banner_1.jpg"),
          url: "/publication",
          type: "route",
        },
      ],
      category: [],
      list: [],
      totalCount: 0,
      queryData: {
        title: null,
        category: "",
        page: 0,
        size: 9,
      },
      listLoading: false,
      device: null,
    };
  },
  created() {
    this.device = getDevice();
    this.getCategory({
      page: 0,
      size: 100,
    }).then((res) => {
      this.category = res.data;
    });
    this.getList();
  },
  destroyed() {
    sessionStorage.removeItem("currentArticleId");
  },
  methods: {
    ...mapActions({
      getCategory: "article/getCategory",
      getArticle: "article/getArticle",
    }),
    checkCategory(item) {
      if (item.name === "期刊") {
        this.$router.push("/publication");
      } else {
        this.queryData.category = item.name;
        this.queryData.title = "";
        this.queryData.page = 0;
        this.getList();
      }
    },
    handleCurrentChange(val) {
      this.queryData.page = val - 1;
      this.getList();
    },
    getList() {
      this.listLoading = true;
      this.list = [];
      this.getArticle(this.queryData).then(
        (res) => {
          for (let item of res.data) {
            // let dd = item.content.replace(/<[^>]+>/g,"");//截取html标签
            // let dds = dd.replace(/&nbsp;/ig,"");//截取空格等特殊标签
            // item.description = dds.substring(0,500)
            item.cover = item.coverUrls[0].replace(
              /\s/g,
              encodeURIComponent(" ")
            );
          }
          this.list = res.data;
          this.totalCount = res.total;
          this.listLoading = false;
          //pageScroll(document.documentElement, 0,"page")
        },
        (err) => {
          this.listLoading = false;
        }
      );
    },
    searchHandle() {
      this.queryData.page = 0;
      this.queryData.category = "";
      this.getList();
    },
    toDetail(item) {
      window.open(window.location.origin + "#/article?id=" + item.id);
    },
    bannerClick(item, i) {
      if (item.type === "route") {
        if (item.url == "/publication" && this.device == "pc") {
          this.$router.push({ path: item.url });
        }
      } else if (item.type === "blank") {
        window.open(item.url, "_blank");
      } else {
      }
    },
  }
};
</script>

<style scoped lang="scss">
@media screen and (min-width: $minDeviceWidth_pc) {
  @import "articleList_pc";
}

@media screen and (max-width: $minDeviceWidth_pc) {
  @import "articleList_mobile";
}
</style>
