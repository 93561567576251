<template>
  <div class="paging" :key="key">
    <div class="btn prev" :class="{canPrev:nowPage > 1}" @click="prev"></div>
    <div class="ellipsis noSelect" v-if="(nowPage > pageCount - 1 ? nowPage > 1 && allPage > pageCount : false)">...</div>
    <div class="pageNums" v-if="type != 'plain'">
      <div class="btn number noSelect" :class="{thisNumber:nowPage==i}" v-for="i in allPage"
           v-if="nowPage == 1 ?
         i <= nowPage + (pageCount - 1) :
         nowPage == allPage ?
         i >= allPage - (pageCount - 1) :
         i >= nowPage - (Math.floor((pageCount - 1)/2)) && i <= nowPage + (Math.ceil((pageCount - 1)/2))"
           @click="checkPage(i)">{{i}}</div>
    </div>
    <div class="pageNums" v-else>
      <div class="btn number noSelect thisNumber">{{nowPage}}</div>
    </div>
    <div class="ellipsis noSelect" v-if="(nowPage < allPage - 1 ? nowPage < allPage - 1 && allPage > pageCount : false)">...</div>
    <div class="btn next noSelect" :class="{canNext:nowPage < allPage}" @click="next"></div>
  </div>
</template>

<script>
  export default {
    name: "Paging",
    data(){
      return {
        nowPage:1,
        allPage:1,
        key:0,
      }
    },
    props:{
      type:{
        type:String,
        default:"normal"
      },
      pageCount:{
        type:Number,
        default:3
      },
      total:{
        type:Number,
        default:0
      },
      current:{
        type:Number,
        default:1
      },
      size:{
        type:Number,
        default:10
      },
      currentChange:{
        type:Function,
        default:() => {}
      }
    },
    created(){
      this.nowPage = this.current
      this.allPage = Math.ceil(this.total / this.size) || 1
    },
    watch:{
      "nowPage"(n,o){
        this.emitCurrent()
      },
      "total"(n,o){
        this.allPage = Math.ceil(n / this.size) || 1
      },
      "current"(n,o){
        if(n < 1) return
        this.nowPage = n
      }
    },
    methods:{
      prev(){
        if(this.nowPage > 1){
          this.nowPage --
        }else{
          return
        }
      },
      next(){
        if(this.nowPage < this.allPage){
          this.nowPage ++
        }else{
          return
        }
      },
      checkPage(i){
        this.nowPage = i
      },
      jump(e){
        var num = parseInt(e.target.value)
        if(isNaN(num) || num < 1) return
        if(num > this.allPage){
          this.nowPage = this.allPage
        }else{
          this.nowPage = num
        }
      },
      keypress(e) {
        var keyCode = e.keyCode ? e.keyCode : e.which ? e.which : e.charCode;
        if (keyCode == 13) {
          this.jump(e)
        }
      },
      emitCurrent(){
        this.$emit('currentChange', this.nowPage)
      }
    }
  }
</script>

<style scoped lang="scss">
  .paging{
    width: 100%;
    @include flexRowCenterColCenter;
    .btn{
      height: 36px;
      width: 36px;
      border-radius: 2px;
      background-color: white;
      color: #333;
      line-height: 36px;
      text-align: center;
      font-size: 12px;
      margin: 0 5px;
      box-shadow: 0 2px 2px 2px rgba(0,0,0,0.1);
    }
    .pageNums{
      @include flexRowStartColCenter;
      .thisNumber{
        background-color: $themeColor;
        color: white;
      }
    }
    .prev{
      @include flexItem000;
      background-image: url("../../assets/image/paging/arrow_left_999.png");
      background-size: 12px 12px;
      background-repeat: no-repeat;
      background-position: center center;
    }
    .canPrev{
      background-image: url("../../assets/image/paging/arrow_left_333.png");
    }
    .next{
      @include flexItem000;
      background-image: url("../../assets/image/paging/arrow_right_999.png");
      background-size: 12px 12px;
      background-repeat: no-repeat;
      background-position: center center;
    }
    .canNext{
      background-image: url("../../assets/image/paging/arrow_right_333.png");
    }
    .ellipsis{
      color: #999;
      line-height: 18px;
      text-align: center;
      font-size: 12px;
    }
    .jumpBox{
      margin-left: 20px;
      div{
        font-size: 12px;
        color: #333;
      }
      .jumpNumber{
        width: 35px;
        height: 20px;
        border-radius: 5px;
        border: 1px solid #999;
        font-size: 12px;
        margin: 0 5px;
        padding: 0 5px;
        text-align: center;
      }
    }
  }
</style>
